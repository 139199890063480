.label {
  display: inline-flex;
  align-items: center;
  font-family: var(--font);
  font-size: 0.9rem;
  font-weight: 300;
  line-height: 1.5em;
  cursor: pointer;
  user-select: none;
}

.input {
  position: relative;
  width: 20px;
  height: 10px;
  margin: 7px 2px;
  appearance: none;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 10px;

  &.padding {
    margin: 7px 10px 7px 4px;
  }

  &::after {
    position: absolute;
    top: 1px;
    width: 6px;
    height: 6px;
    content: '';
    border-radius: 50%;
  }

  &:not(:checked) {
    background-color: white;

    &::after {
      right: unset;
      left: 1px;
      background-color: black;
    }
  }

  &:checked {
    background-color: black;

    &::after {
      right: 1px;
      left: unset;
      background-color: white;
    }
  }
}
