.root {
  position: relative;
}

.padding-big {
  padding: spacing(8, 0);
  padding-top: spacing(6);
}

.padding-regular {
  padding: spacing(6, 0);
  padding-top: spacing(4);
}

.padding-small {
  padding: spacing(4, 0);
  padding-top: spacing(3);
}

.padding-none {
  padding: spacing(0);
}

.background-white {
  color: black;
  background: white;
}

.background-off-white {
  color: var(--primary);
  background-color: var(--off-white);
}

.background-secondary {
  color: var(--primary);
  background-color: var(--secondary);
}
