.root {
  @supports (display: grid) {
    display: grid;
  }
}

.gap-small {
  grid-gap: var(--gap-small);
}

.gap-regular {
  grid-gap: var(--gap);
}

.gap-big {
  grid-gap: var(--gap-big);
}

.columns-2 {
  grid-template-columns: repeat(2, 1fr);

  @media ($tablet-portrait-down) {
    grid-template-columns: 1fr;
  }
}

.columns-3 {
  grid-template-columns: repeat(3, 1fr);

  @media ($tablet-portrait-down) {
    grid-template-columns: 1fr;
  }
}

.columns-4 {
  grid-template-columns: repeat(4, 1fr);

  @media ($tablet-landscape-down) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ($tablet-portrait-down) {
    grid-template-columns: 1fr;
  }
}
