.root {
  position: relative;
  display: block;
}

.label {
  position: absolute;
  bottom: spacing(1);
  left: spacing(1);
}

.inner {
  position: relative;
}

.image {
  position: relative;
  flex: 1;
  width: 100%;
  padding-top: calc(7 / 5 * 100%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image .media {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
}

.information {
  display: flex;
  flex-direction: column;
  padding: spacing(0.5, 1);
  margin-top: spacing(1);

  .price {
    margin-top: spacing(0.5);
  }
}

.title {
  text-transform: uppercase;
}

.name {
  margin-bottom: spacing(1);
  font-family: var(--font);
  text-transform: uppercase;
}

.sold {
  opacity: 0.6;
}
