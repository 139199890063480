.section {
  display: flex;
  flex-direction: column;
  gap: 64px;
  margin: 64px 0;

  & > h2 {
    font-size: 1rem;
    font-weight: 500;
  }

  @media screen and ($tablet-landscape-down) {
    gap: 32px;
    margin: 64px 32px;
  }
}

.stores {
  display: flex;
  flex-wrap: wrap;
  gap: 64px;

  @media screen and ($tablet-landscape-down) {
    flex-direction: column;
    gap: 8px;
  }
}

.item {
  display: contents;
}

.store {
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 240px;

  & > a {
    font-weight: 500;
  }
}

.name {
  position: relative;
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: expanded;
  letter-spacing: 0.5px;
  list-style: none;

  &::-webkit-details-marker,
  &::marker {
    display: none;
  }

  &::after {
    position: absolute;
    top: 0;
    bottom: 2px;
    width: 0;
    height: 0;
    margin: auto 4px;
    content: '';
    border-top: 8px solid black;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    transform: rotate(270deg);
  }
}

.details[open] > .name::after {
  transform: rotate(0deg);
}

.city {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cityHeading {
  font-size: 0.875rem;
  font-weight: 500;
}

.find {
  font-weight: 400 !important;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 8px;
}
