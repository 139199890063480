.inspiration {
  position: relative;
  height: min-content;
}

.point {
  position: absolute;
  z-index: 1;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-color: white;
  border-radius: 24px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  transform: translate(-50%, -50%);
}

.box {
  position: absolute;
  z-index: 1;
  width: fit-content;
  max-width: 40%;
  padding: spacing(0.5, 1);
  pointer-events: none;
  user-select: none;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  opacity: 0;
  transition:
    0.25s opacity,
    0.25s transform;
  transform: translateY(10px);

  &.shown {
    pointer-events: all;
    cursor: pointer;
    opacity: 1;
    transform: translateY(0);

    &.sold {
      opacity: 0.6;
    }
  }

  p {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.title {
  padding: spacing(0.5, 1);
  margin-top: spacing(1);
  text-transform: uppercase;
}
