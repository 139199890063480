.hero {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.caption {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 25%;
  margin: 38px;

  & > h2 {
    font-size: 1.5rem;
    font-weight: 500;
  }

  & > p {
    font-size: 0.875rem;
    font-weight: 400;
  }

  & > a {
    font-size: 0.875rem;
    font-weight: 500;
    text-decoration: underline;
  }

  &.white {
    color: white;
  }

  &.whiteMobile {
    color: black;

    @media screen and ($tablet-landscape-down) {
      color: white;
    }
  }

  &.black {
    color: black;
  }

  &.blackMobile {
    color: white;

    @media screen and ($tablet-landscape-down) {
      color: black;
    }
  }

  &.top {
    top: 0;
  }

  &.middle {
    top: 50%;
    transform: translateY(-125%);
  }

  &.bottom {
    bottom: 0;
  }

  &.half {
    width: 50%;

    @media screen and ($tablet-landscape-down) {
      width: 100%;
    }
  }

  &.top,
  &.middle,
  &.bottom {
    @media screen and ($tablet-landscape-down) {
      top: unset;
      bottom: 0;
      width: unset;
      transform: unset;
    }
  }
}

.split {
  display: flex;

  @media screen and ($tablet-landscape-down) {
    flex-direction: column;
  }
}

.image {
  position: relative;
  flex: 1;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &.wide {
    padding-top: calc(9 / 16 * 100%);

    @media screen and ($tablet-landscape-down) {
      padding-top: calc(7 / 5 * 100%);
    }
  }

  &.half {
    padding-top: calc(7 / 5 * 100%);
  }
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image .media {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
}
