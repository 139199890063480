.desc {
  color: #999;
}

.cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button {
  margin-bottom: spacing(1.5);
}

.textCont {
  max-width: 700px;
}
