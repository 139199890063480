.grid {
  @media screen and ($desktop-down) and ($phone-up) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and ($tablet-portrait-down) and ($phone-up) {
    grid-template-columns: 1fr;
  }
}

.collectionContainer {
  display: flex;
  justify-content: center;
  padding: 0;
  margin-top: spacing(6);
  margin-bottom: spacing(4);
}

.collectionList {
  display: inline-flex;
  gap: spacing(1);
  justify-content: flex-start;
  padding: spacing(0, 1, 1);
  margin-bottom: spacing(-1);
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  list-style: none;
  scrollbar-width: none;
}

.collectionList::-webkit-scrollbar {
  width: 0;
  height: 0;
  appearance: none;
  background-color: transparent;
}

.collectionList::-webkit-scrollbar-track {
  background-color: transparent;
}

.collectionList::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.hideSold {
  position: sticky;
  top: var(--nav-height);
  z-index: var(--z-nav);
  margin-top: spacing(1);
  background-color: white;
}

.somethingIsWrongContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}
