.root {
  display: inline-flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  width: 96px;
  padding: 0;
}

.image {
  width: 80px;
  margin-right: spacing(1);
  margin-left: spacing(1);
  border: 1px solid var(--border-color);
  border-radius: 50%;
  object-fit: cover;
}

.label {
  width: 100%;
  overflow: hidden;
  font-family: var(--font);
  font-size: 0.75rem;
  font-weight: 300;
  color: black;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;

  &.active {
    font-weight: 700;
  }
}
