.root {
  background-color: var(--loading);

  &.absolute {
    position: relative;
    overflow: hidden;

    &::before {
      display: block;
      width: 100%;
      content: '';
    }
  }
}

.aspect-ratio-square::before {
  padding-top: 100%;
}

.aspect-ratio-wide::before {
  padding-top: 56.25%;
}

.aspect-ratio-letterbox::before {
  padding-top: 75%;
}

.aspect-ratio-letterbox-portrait::before {
  padding-top: calc(7 / 5 * 100%);
}

.image {
  &.absolute {
    position: absolute;
    object-fit: cover;
    object-position: center center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  opacity: 0;
  transition: opacity 0.15s ease-out;
}

.loaded {
  opacity: 1;
}
